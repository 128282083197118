import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { StyledProps } from 'styled-components'

import { Maybe } from '../../../graphql/gatsby'
import { pillarBox1920Styles } from '../../../lib/styles/pillar-box'
import BodyPortableText from '../BodyPortableText'
import {
  backgroundStyles,
  BackgroundStylesMixin,
  ctaBackgroundColorOrDefault,
  ctaTextColorOrDefault,
  fadeBackgroundInFromAbove,
  textStylesFor,
} from '../styles'
import { SanityBannerWithTextFiltered } from './types'

export const BannerWithText = styled.div<BackgroundStylesMixin>(backgroundStyles)

export const MaxWidthContainer = styled.div`
  position: relative;
  ${pillarBox1920Styles};
  @media (min-width: 992px) {
    min-height: 465px;
  }
  @media (min-width: 1440px) {
    min-height: 670px;
  }
`

export const ImageWrapper = styled(GatsbyImage)`
  width: 100%;
  height: 431px;
  @media (min-width: 600px) {
    height: 508px;
  }
  @media (min-width: 768px) {
    height: 553px;
  }
  @media (min-width: 880px) {
    height: 633px;
  }
  @media (min-width: 992px) {
    // override inline style
    position: absolute !important;
    height: 100%;
  }
`

const textBackgroundColorOrDefault = ({
  $textBackgroundColor,
  theme,
}: StyledProps<{
  $textBackgroundColor: SanityBannerWithTextFiltered['textBackgroundColor']
}>) => $textBackgroundColor?.color || theme.colors.coolGray.cool150

export const ImageToTextTransition = styled.div<{
  $textBackgroundColor: SanityBannerWithTextFiltered['textBackgroundColor']
}>`
  width: 100%;
  position: relative;
  ${(props) => fadeBackgroundInFromAbove(50, textBackgroundColorOrDefault(props))};
`

export const GridContainer = styled.div`
  display: none;
  position: relative;
  padding: 128px 128px 64px;
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'left center right';
  }
  @media (min-width: 1440px) {
    padding: 192px 192px 64px;
  }
`

const validTextPositions = ['left', 'center', 'right']

export const TextInGrid = styled.div<{
  $textStyles: SanityBannerWithTextFiltered['textStyles']
  $textPosition: Maybe<string> | undefined
}>`
  ${({ $textStyles }) => textStylesFor($textStyles)};
  grid-area: ${({ $textPosition }) =>
    $textPosition && validTextPositions.includes($textPosition) ? $textPosition : 'left'}};
`

export const LongCta = styled(PrimaryButton)<{
  $ctaTextColor: SanityBannerWithTextFiltered['ctaTextColor']
  $ctaBackgroundColor: SanityBannerWithTextFiltered['ctaBackgroundColor']
}>`
  --btn-height: 46px;
  padding-left: 32px;
  padding-right: 32px;
  font-size: 15px;
  margin-top: 1rem;
  color: ${ctaTextColorOrDefault};
  background-color: ${ctaBackgroundColorOrDefault};
  @media (min-width: 1200px) {
    font-size: 1rem;
  }
`

export const ShortCta = styled(PrimaryButton)<{
  $ctaTextColor: SanityBannerWithTextFiltered['ctaTextColor']
  $ctaBackgroundColor: SanityBannerWithTextFiltered['ctaBackgroundColor']
}>`
  --btn-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  margin: 2rem 15% 0 15%;
  width: 75%;
  font-size: 1rem;
  color: ${ctaTextColorOrDefault};
  background-color: ${ctaBackgroundColorOrDefault};
  @media (min-width: 432px) {
    width: 50%;
  }
  @media (min-width: 600px) {
    width: 40%;
  }
  @media (min-width: 768px) {
    width: 33%;
  }
`

export const Blocks = styled(BodyPortableText)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-block-start: 0;
    margin-block-end: 0;
    white-space: nowrap;
    line-height: 1.1;
    @media (min-width: 1440px) {
      font-size: 46px;
    }
  }
  @media (min-width: 1440px) {
    p {
      font-size: 18px;
    }
  }
`

export const Text = styled.div<{
  $textBackgroundColor: SanityBannerWithTextFiltered['textBackgroundColor']
  $textStyles: SanityBannerWithTextFiltered['textStyles']
}>`
  min-height: 200px;
  padding-bottom: 4rem;
  && {
    ${({ $textStyles }) => textStylesFor($textStyles)}
  }
  background-color: ${textBackgroundColorOrDefault};
  @media (min-width: 992px) {
    display: none;
  }
`

export const TextBlock = styled(BodyPortableText)`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  align-self: center;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-start: 0;
    margin-block-end: 0;
    display: block;
    position: relative;
    margin: 0 15%;
    z-index: 1;
    line-height: 1.1;
    font-family: ${({ theme }) => theme.fontFamilies.gotham};
    font-weight: bold;
    font-size: 28px;
    text-align: left;
    letter-spacing: 0.025em;
    color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
    &:first-of-type {
      padding-top: 2rem;
    }
  }
  p {
    font-family: ${({ theme }) => theme.fontFamilies.gotham};
    display: block;
    position: relative;
    width: 75%;
    max-width: 75%;
    margin: 0 15%;
    padding-top: 1rem;
    text-align: left;
    @media (min-width: 768px) {
      width: 60%;
      max-width: 60%;
    }
  }
  @media (min-width: 992px) {
    display: none;
  }
`

import { bind } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'
import { of, startWith, switchMap } from 'rxjs'

import { right } from '../../utils/result'
import { mapRight, switchMapRight } from '../../utils/rx/operators'
import { searchExactQuery } from '../search/exact-query'
import { dispensariesSiteSettingsCdn$, sanityDispensaryCdn$ } from './query'
import { latestDispensarySlug$ } from './state'
import { mainProductListWithDefaults } from './utils'

const defaultProductList$ = dispensariesSiteSettingsCdn$.pipe(
  mapRight((siteSettingsResult) => ({
    _tag: 'Right' as const,
    data: mainProductListWithDefaults(siteSettingsResult.data?.defaultDispensaryProductLists),
  })),
)

const dispensaryProductList$ = sanityDispensaryCdn$.pipe(
  mapRight((dispensaryResult) => ({
    _tag: 'Right' as const,
    data: mainProductListWithDefaults(dispensaryResult.data?.productLists),
  })),
)

const dispensaryProductListWithDefault$ = dispensaryProductList$.pipe(
  switchMap((dispensarySkusResult) =>
    dispensarySkusResult._tag === 'Left' || dispensarySkusResult.data.skus.length > 0
      ? of(dispensarySkusResult)
      : defaultProductList$,
  ),
)

const [searchFeaturedProducts$, searchFeaturedProducts] = createSignal<{
  forceDefault?: boolean | null
}>()

export { searchFeaturedProducts }

const featuredProducts$ = searchFeaturedProducts$.pipe(
  switchMap(({ forceDefault }) =>
    forceDefault
      ? defaultProductList$
      : latestDispensarySlug$.pipe(
          switchMap((dispensarySlug) =>
            !dispensarySlug ? defaultProductList$ : dispensaryProductListWithDefault$,
          ),
        ),
  ),
  switchMapRight(({ data: { title, skus } }) =>
    searchExactQuery({ skus }).pipe(
      mapRight((value) => right({ title, products: value.data })),
      startWith(right({ title: '', products: [] })),
    ),
  ),
)

export const [useFeaturedProducts, latestFeaturedProducts$] = bind(featuredProducts$)

import { graphql } from 'gatsby'
import { withArtDirection } from 'gatsby-plugin-image'
import React, { useMemo } from 'react'

import { useSanityRoleAndContextBasedContent } from '../../../lib/sanity'
import { getGatsbyImageDataForAsset } from '../../../utils/sanity/image'
import SanityLink from '../../global/SanityLink'
import * as Styled from './styled'
import { SanityBannerWithTextFiltered } from './types'

export interface BannerWithTextProps extends SanityBannerWithTextFiltered {
  className?: string
}

export const BannerWithText: React.FC<BannerWithTextProps> = ({
  className,
  backgroundStyles,
  heroImage,
  heroImageMobile,
  content,
  textBackgroundColor,
  textPosition,
  textStyles,
  cta,
  ctaBackgroundColor,
  ctaTextColor,
  roleBasedContent,
  contextBasedContent,
}) => {
  const { shouldDisplayContent } = useSanityRoleAndContextBasedContent()
  const shouldDisplay = useMemo(
    () => shouldDisplayContent({ contextBasedContent, roleBasedContent }),
    [contextBasedContent, roleBasedContent, shouldDisplayContent],
  )

  const imageElement = useMemo((): JSX.Element | undefined => {
    const mobileImageData = getGatsbyImageDataForAsset(heroImageMobile ?? heroImage, {
      width: 576,
      height: 431,
      placeholder: 'blurred',
    })
    const tabletImageData = getGatsbyImageDataForAsset(heroImageMobile ?? heroImage, {
      width: 991,
      height: 633,
      placeholder: 'blurred',
    })
    const desktopImageData = getGatsbyImageDataForAsset(heroImage, {
      placeholder: 'blurred',
    })

    const images =
      mobileImageData && tabletImageData && desktopImageData
        ? withArtDirection(desktopImageData, [
            {
              image: mobileImageData,
              media: '(max-width: 576px)',
            },
            {
              image: tabletImageData,
              media: '(max-width: 991px)',
            },
            {
              image: desktopImageData,
              media: '(min-width: 992px)',
            },
          ])
        : desktopImageData

    return images ? <Styled.ImageWrapper image={images} loading="eager" alt="" /> : undefined
  }, [heroImage, heroImageMobile])

  if (!shouldDisplay) {
    return null
  }

  return (
    <Styled.BannerWithText $backgroundStyles={backgroundStyles}>
      <Styled.MaxWidthContainer className={className}>
        {imageElement}
        <Styled.GridContainer>
          <Styled.TextInGrid $textPosition={textPosition} $textStyles={textStyles}>
            {content && <Styled.Blocks blocks={content} />}
            {cta && cta.link && cta.link.target && cta.link.target.length > 0 && (
              <Styled.LongCta
                as={SanityLink}
                to={cta.link}
                $ctaBackgroundColor={ctaBackgroundColor}
                $ctaTextColor={ctaTextColor}
              >
                {cta.title}
              </Styled.LongCta>
            )}
          </Styled.TextInGrid>
        </Styled.GridContainer>
        <Styled.Text $textBackgroundColor={textBackgroundColor} $textStyles={textStyles}>
          {imageElement && (
            <Styled.ImageToTextTransition $textBackgroundColor={textBackgroundColor} />
          )}
          {content && <Styled.TextBlock blocks={content} />}
          {cta && cta.link && cta.link.target && cta.link.target.length > 0 && (
            <Styled.ShortCta
              as={SanityLink}
              to={cta.link}
              $ctaBackgroundColor={ctaBackgroundColor}
              $ctaTextColor={ctaTextColor}
            >
              {cta.title}
            </Styled.ShortCta>
          )}
        </Styled.Text>
      </Styled.MaxWidthContainer>
    </Styled.BannerWithText>
  )
}

export const bannerWithTextFragment = graphql`
  fragment bannerWithText on SanityBannerWithText {
    backgroundStyles {
      backgroundColor {
        color
      }
    }
    _rawContent
    cta {
      title
      link {
        ...LinkFragment
      }
    }
    ctaBackgroundColor {
      color
    }
    ctaTextColor {
      color
    }
    heroImage {
      ...SanityImageObject
    }
    heroImageMobile {
      ...SanityImageObject
    }
    roleBasedContent {
      ...RoleBasedContentFragment
    }
    contextBasedContent {
      ...ContextBasedContentFragment
    }
    textBackgroundColor {
      color
    }
    textPosition
    textStyles {
      headerColor {
        color
      }
      textAlign
      textColorV2 {
        color
      }
    }
  }
`

import { css } from 'styled-components'

// Styles for pillar boxing (rendering background left and right of) component
// beyond a width of 1920px
// Note: we're intending to remove the need for pillar boxing on new components
export const pillarBox1920Styles = css`
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
`
